<template>
  <UiHeader
    :header="props.header"
    :subheader="props.subheader"
    :header-layout="props.headerLayout"
    :header-position="props.headerPosition"
    :header-link="props.headerLink"
    :header-style-class="headerColor"
  />
</template>

<script lang="ts" setup>
import UiHeader from '@ui/components/UiHeader/UiHeader.vue'
import type { UiHeaderProps } from '@ui/components/UiHeader/useUiHeader'
import { getTypo3HeaderColor } from '../../helpers/typo3-colors'

const props = withDefaults(defineProps<UiHeaderProps>(), {
  header: '',
  headerLayout: 0,
  headerPosition: '',
  headerColor: '',
  headerLink: '',
  subheader: '',
})

const headerColor = getTypo3HeaderColor(props.headerColor)
</script>
